import { RoleType } from '../services/api/user/userInfo';

const { href } = window.location;

const API_SERVICE = href.includes(
  process.env.REACT_APP_NEW_DOMAIN_URL as string
)
  ? process.env.REACT_APP_API_SERVICE_NEW_DOMAIN
  : href.includes(process.env.REACT_APP_BASE_URL as string) ||
    href.includes(process.env.REACT_APP_LAB_URL as string)
  ? process.env.REACT_APP_API_SERVICE
  : process.env.REACT_APP_PROXY_API_SERVICE;

const API_SKILLO =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_SKILLO_LIVE
    : process.env.REACT_APP_API_SKILLO;

export const DASHBOARD_API = Object.freeze({
  ProjectList: `${API_SERVICE}/projects`, // GET
  ProjectSearch: `${API_SERVICE}/projects/search`, // POST
  ProjectPictureUrl: `${API_SERVICE}/{0}`, // GET, 0: imgUrl(string)
  ProjectById: (projectId: number | string) =>
    `${API_SERVICE}/projects/${projectId}`, // GET
  ProjectMemberPositions: `${API_SERVICE}/dictionary/project-position`,
  TelescopeUsers: (name: string) => `${API_SERVICE}/users?query=${name}`,
  SkilloTechs: (skill: string, numOfRes: number) =>
    `${API_SKILLO}lookup?size=${numOfRes}&parents=Engineering Practices,Technologies&duplicate=false&q=${skill}`,
  SkilloTechsByID: (ids: string) => `${API_SKILLO}${ids}`,
  DeleteProjectCoverImg: (id: number) =>
    `${API_SERVICE}/projects/${id}/picture`, // DELETE - Request to delete project cover img
  GetProjectStatusById: (id: number) => `${API_SERVICE}/projects/${id}/status`, //GET
  RestoreProjectById: (id: number) => `${API_SERVICE}/projects/${id}/restore`,
});

export const USER_API = Object.freeze({
  Info: `${API_SERVICE}/loggedin`, // GET
  UserProfilePicture: `${API_SERVICE}/users/{0}/picture`, // GET, 0: userId(string/number)
});

export const ProjectManagementAPI = Object.freeze({
  ProjectLinks: `${API_SERVICE}/dictionary/project-link-type`, // GET
  CreateProject: `${API_SERVICE}/projects`,
  UpdateProject: (id: number) => `${API_SERVICE}/projects/${id}`,
  UpdateProjectEndDate: (id: number) =>
    `${API_SERVICE}/projects/${id}/plannedEndDate`,
  DeleteProject: (id: number) => `${API_SERVICE}/projects/project/${id}`,
  UpdateProjectStatus: (id: string | number, status: string) =>
    `${API_SERVICE}/projects/${id}/status?status=${status}`, // POST
  AddOpportunity: (id: number) => `${API_SERVICE}/projects/${id}/opportunity`, // POST-Add opportunity to specific project
  GetFieldsStatusToAddProjectToShowroom: (id: number, link?: string) =>
    `${API_SERVICE}/projects/${id}/showroom-request${
      link ? `?link=${link}` : ''
    }`, // GET- project fields status
  AddShowroomRequest: (id: number) =>
    `${API_SERVICE}/projects/${id}/showroom-request`, //POST-Request to add project to showroom
  RemoveShowroomRequest: (id: number) =>
    `${API_SERVICE}/projects/project/${id}/showroom`, //DELETE-Request to remove project from showroom
  TransferOwnership: (projectId: number | string) =>
    `${API_SERVICE}/projects/${projectId}/membership/owner`,
  AcceptShowroomRequest: (id: number) =>
    `${API_SERVICE}/showroom_requests/${id}/accept`, // GET-Showroom request accept
  DeclineShowroomRequest: (id: number) =>
    `${API_SERVICE}/showroom_requests/${id}/decline`, // GET-Showroom request decline
  RevokeShowroomRequest: (id: number) =>
    `${API_SERVICE}/showroom_requests/${id}/revoke`, // GET-Showroom request revoke

  //Hitting below URLs (GetContributionReport, GetProjectReport) directly will download a Report
  GetContributionReport: (
    excelVersion: string | any,
    since: string,
    until: string
  ) =>
    `${API_SERVICE}/projects/contribution_report/excel?excel%20version=${excelVersion}&since=${since}&until=${until}`,
  GetProjectReport: (excelVersion: string | any) =>
    `${API_SERVICE}/projects/project_report/excel?excel%20version=${excelVersion}`,
});

export const DICT_API = Object.freeze({
  GetDictionary: (dictionary: string) =>
    `${API_SERVICE}/dictionary/${dictionary}`,
});

export const OPPORTUNITY_API = Object.freeze({
  DeleteOpportunity: (id: number) => `${API_SERVICE}/opportunities/${id}`, //DEL-OPPORTUNITY with Opportunity ID
  EditOpportunity: (id: number) => `${API_SERVICE}/opportunities/${id}`, //Edit Opportunity with opportunity ID
  RefreshOpportunity: (id: number) =>
    `${API_SERVICE}/opportunities/${id}/refresh`, //Refresh Opportunity with opportunity ID
  GetOpportunities: (
    amount: number,
    startPoint: number,
    searchPhrase: string,
    positions?: string[],
    technologies?: string[]
  ) => {
    const positionsStr = positions?.length
      ? `&positions=${positions.join(',')}`
      : '';
    const technologiesStr = technologies?.length
      ? `&technologies=${technologies.join(',')}`
      : '';
    return `${API_SERVICE}/opportunities?offset.amount=${amount}&offset.startPoint=${startPoint}&searchPhrase=${searchPhrase}${positionsStr}${technologiesStr}`;
  },
  RequestToJoinOpportunity: (id: number) =>
    `${API_SERVICE}/opportunities/${id}/join`,
  GetSubscription: `${API_SERVICE}/opportunities/subscribe`, // GET
  EditSubscription: `${API_SERVICE}/opportunities/subscribe`, // PUT
});

export const MEMBER_API = Object.freeze({
  GetMembershipRequest: (id: number) =>
    `${API_SERVICE}/membership_requests/${id}`,
  AcceptMembershipRequest: (id: number, comment?: string) =>
    `${API_SERVICE}/membership_requests/${id}/accept?comment=${comment ?? ''}`,
  DeclineMembershipRequest: (id: number, comment?: string) =>
    `${API_SERVICE}/membership_requests/${id}/decline?comment=${comment ?? ''}`,
  RevokeMembershipRequest: (id: number) =>
    `${API_SERVICE}/membership_requests/${id}/revoke`,
  DeleteProjectMember: (projectId: number | string, externalUserId: string) =>
    `${API_SERVICE}/projects/${projectId}/membership/${externalUserId}`,
  AddProjectMember: (projectId: number | string) =>
    `${API_SERVICE}/projects/${projectId}/membership`,
  EditProjectMember: (projectId: number | string, memberId: string) =>
    `${API_SERVICE}/projects/${projectId}/membership/${memberId}`,
});

export const GARAGE_USER_API = Object.freeze({
  getListGarageUsers: ({
    amount,
    startPoint,
    searchPhrase,
  }: {
    amount: number;
    startPoint: number;
    searchPhrase?: string;
  }) =>
    `${API_SERVICE}/users/local?offset.amount=${amount}&offset.startPoint=${startPoint}${
      (searchPhrase || '').length > 0 ? `&searchPhrase=${searchPhrase}` : ''
    }`,
});

export const PERMISSION_API = Object.freeze({
  transferSuperAdminToUser: (userID: number) =>
    `${API_SERVICE}/permissions/transfer/${userID}`,
  addPermissionToUser: (userID: number, permission: RoleType) =>
    `${API_SERVICE}/permissions/users/${userID}?permission=${permission}`,
  removePermissionToUser: (userID: number, permission: RoleType) =>
    `${API_SERVICE}/permissions/users/${userID}?permission=${permission}`,
  getUsersWithSpecifiedPermission: (permission: RoleType) =>
    `${API_SERVICE}/permissions/users/?permission=${permission}`,
  getProjectMembersPermissions: () => `${API_SERVICE}/permissions/project`,
});

export const EVENTS_API = Object.freeze({
  GetEvents: (
    amount: number,
    startPoint: number,
    searchPhrase: string,
    isValidated: boolean
  ) =>
    `${API_SERVICE}/challenges?offset.amount=${amount}&offset.startPoint=${startPoint}&searchPhrase=${searchPhrase}${
      isValidated ? '&validRegistrationDate=true' : ''
    }`,
  CreateEvent: () => `${API_SERVICE}/challenges`,
  EditEvent: (eventID: number | string) =>
    `${API_SERVICE}/challenges/${eventID}`,
  GetEventById: (eventID: number | string) =>
    `${API_SERVICE}/challenges/${eventID}`,
  DeleteEventById: (eventID: number | string) =>
    `${API_SERVICE}/challenges/${eventID}`,
  GetEventNames: () => `${API_SERVICE}/challenges/names`,
});

export const REPORTS_API = Object.freeze({
  RequestProject: (projectId: number) =>
    `${API_SERVICE}/projects/${projectId}/report`,
});

export const KEYWORDS_API = Object.freeze({
  GetKeywords: (amount: number, searchPhrase: string) =>
    `${API_SERVICE}/keywords?amount=${amount}&searchPhrase=${searchPhrase}`,
});
