import {
  ModalBlocker,
  ModalHeader,
  ModalWindow,
  ScrollBars,
  useForm,
  Text,
  SuccessNotification,
} from '@epam/promo';
import './styles.scss';
import { joinUsersLinksWithMissingDefaultLinksFromTheForm } from './helpers/utils';
import { INotification, useUuiContext } from '@epam/uui';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import { useEffect, useState } from 'react';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useBlocker } from 'hooks/useBlocker';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { AddToShowroomProps, LinkDictionary } from './models/interfaces';
import { STEPS_ADD_TO_SHOWROOM } from './constants';
import { LINK_TYPES } from '../project-links/constants';
import { getLinkByType } from './helpers/utils';
import { PROJECT_LINKS } from './helpers/utils';
import { MAX_PROJECT_LINKS } from 'constants/uiConstants';
import { addToShowroomSchema } from './models/validationSchema';
import { prepareFormData } from 'modules/project-management/update/utils';
import {
  IProjectLink,
  ProjectLinkType,
} from 'modules/project-management/models/interfaces';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';
import Footer from './Footer';
import StepperController from 'component/Stepper';
import WarningMessage from '../../../../../component/Artifacts/WarningMessage';
import OptionalArtifactsForm from './tabs/OptionalArtifactsForm';
import RequiredArtifactsForm from './tabs/RequiredArtifactsForm';
import ProjectManagementService from 'services/api/project-management/projectManagementService';

function AddToShowroom({
  modalProps,
  info,
  links,
  userHasActiveRepository,
  enableCancelShowroomButton,
}: AddToShowroomProps) {
  const [currentLinkCount, setCurrentLinkCount] = useState(0);
  const [dictionary, setDictionary] = useState<ProjectLinkType[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const svc = useUuiContext();
  const steps = [
    STEPS_ADD_TO_SHOWROOM.REQUIRED_ARTIFACTS,
    STEPS_ADD_TO_SHOWROOM.OPTIONAL_ARTIFACTS,
    '',
  ];
  const isReachedLinkLimit = currentLinkCount === MAX_PROJECT_LINKS;
  const completeLinks = joinUsersLinksWithMissingDefaultLinksFromTheForm(links);
  const { DOCS_DECKS, SLIDE_DECK, CASE_STUDY, EMAIL } = LINK_TYPES;
  const gitlabUrl = info?.labUrl;

  const { lens, save, isChanged, validate } = useForm({
    validationOn: 'save',
    value: {
      GIT_REPO: gitlabUrl,
      projectLinks: completeLinks,
      userHasActiveRepository: userHasActiveRepository,
    },
    onSave: async (showroomFormData) => {
      useBlocker(svc);
      const filterredLinks = showroomFormData.projectLinks
        .filter(
          (link: IProjectLink) => link.link && typeof link.link === 'string'
        )
        .map((link: IProjectLink) => ({
          ...link,
          link: link.linkType === EMAIL ? `mailto:${link.link}` : link.link,
        }));
      const slideDeck = getLinkByType(
        showroomFormData.projectLinks,
        SLIDE_DECK
      );
      const caseStudy = getLinkByType(
        showroomFormData.projectLinks,
        CASE_STUDY
      );
      const addToShowroomData: any = {
        id: parseInt(info.id),
        slideDeck: slideDeck?.file,
        caseStudy: caseStudy?.file,
        projectLinks: filterredLinks,
      };
      const preparedFormData = prepareFormData(addToShowroomData);
      await ProjectManagementService.addShowroomRequest(
        parseInt(info.id),
        preparedFormData
      );
      await Promise.all([
        queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_DETAILS]),
      ]);
      return Promise.resolve({ form: showroomFormData });
    },
    onSuccess: () => {
      useCloseBlocker(svc);
      modalProps.success(true);
      enableCancelShowroomButton();
      svc.uuiNotifications
        .show(
          (props: INotification) => (
            <SuccessNotification {...props}>
              <Text>
                {NOTIFICATION_MESSAGES.success.createdShowroomRequest}
              </Text>
            </SuccessNotification>
          ),
          { position: 'bot-left', duration: 5 }
        )
        .catch(() => null);
    },
    onError: (errors: validationErrors) => {
      useCloseBlocker(svc);
      enableCancelShowroomButton();
      showErrorMessages(svc, errors);
    },
    getMetadata: addToShowroomSchema,
  });

  const projectLinks = lens.prop(PROJECT_LINKS).get();

  const isWithAtLeastOneRequiredFieldInvalid =
    lens.prop(DOCS_DECKS).toProps().isInvalid ||
    lens.prop(SLIDE_DECK).toProps().isInvalid;

  const showLeaveConfirm = useLeaveConfirm(modalProps, isChanged);
  const totalSteps = () => steps.length;

  const isLastStep = (n = 1) => activeStep === totalSteps() - n;

  const handleNext = () => {
    completeStep();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((currentAciveStep) => currentAciveStep - 1);
  };

  const completeStep = () => {
    const newCompleted = completed;
    newCompleted[activeStep + 1] = true;
    setCompleted(newCompleted);
  };

  const stepContent: { [key: number]: ReactJSXElement } = {
    1: (
      <RequiredArtifactsForm
        lens={lens}
        dictionary={dictionary}
        projectLinks={projectLinks}
        isReachedLinkLimit={isReachedLinkLimit}
        className="add-to-showroom-modal"
        currentLinkCount={currentLinkCount}
        gitlabUrl={gitlabUrl}
      />
    ),
    2: (
      <OptionalArtifactsForm
        lens={lens}
        dictionary={dictionary}
        projectLinks={projectLinks}
        isReachedLinkLimit={isReachedLinkLimit}
        className="add-to-showroom-modal"
        currentLinkCount={currentLinkCount}
      />
    ),
  };

  useEffect(() => {
    (async () => {
      const linksDictionary: LinkDictionary =
        (await ProjectManagementService.getProjectLinks()) ?? { items: [] };
      setDictionary(linksDictionary.items);
    })();
  }, []);

  useEffect(() => {
    setCurrentLinkCount(projectLinks.length + 1); // add + 1 because 'Code in Gitlab' link is not part of the user's projectLinks but is the part of users info
  }, [projectLinks]);

  return (
    <ModalBlocker blockerShadow="dark" {...modalProps} abort={showLeaveConfirm}>
      <ModalWindow cx="add-to-showroom-modal">
        <ModalHeader
          borderBottom
          title="Add to Showroom"
          onClose={showLeaveConfirm}
          cx={'add-to-showroom-modal__header'}
        />
        <StepperController
          activeStep={activeStep}
          steps={steps}
          stepClick={setActiveStep}
          stepsCompleted={completed}
          enableAllSteps
        />
        {activeStep === 0 && isWithAtLeastOneRequiredFieldInvalid && (
          <WarningMessage
            message="Before adding a project to the Showroom, the below requirements have to be satisfied:"
            className={'add-to-showroom-modal__warning-alert'}
          />
        )}
        <ScrollBars
          hasTopShadow
          hasBottomShadow
          cx="add-to-showroom-modal__main"
        >
          {stepContent[activeStep + 1]}
        </ScrollBars>
        <Footer
          lens={lens}
          saveForm={save}
          validate={validate}
          isNotLastStep={!isLastStep(2)}
          setNextStep={handleNext}
          setPreviousStep={handlePrevious}
          projectLinks={projectLinks}
          projectId={parseInt(info.id)}
        />
      </ModalWindow>
    </ModalBlocker>
  );
}
export default AddToShowroom;
