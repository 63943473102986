import { emailRegExp, validURLRegExp } from 'constants/regexpConstants';
import { IProjectLink } from 'modules/project-management/models/interfaces';
import { LINK_TYPES } from '../../project-links/constants';
import { getLinkByType } from '../helpers/utils';

const { GIT_OTHER, GIT_GITHUB, EMAIL, CASE_STUDY, SLIDE_DECK, DOCS_DECKS } =
  LINK_TYPES;

const requiredLinksTypes = [DOCS_DECKS];

export const SHOWROOM_VALIDATION_ERRORS = Object.freeze({
  FIELD_IS_REQUIRED: 'This field is required.',
  ATTACH_YOUR_FILE: 'Please attach your file',
  INVALID_EPAM_GITLAB_LINK:
    'Showroom request contains invalid EPAM GitLab link.',
  NO_ACTIVITY_IN_THE_REPOSITORY:
    'No activity in the repository. Please add another source.',
  WRONG_EMAIL_FORMAT:
    'Email should correspond to the format i.e. sample@domain.com.',
  LINK_FORMAT_INVALID:
    'Link does not have a proper URL pattern i.e. http://example.com',
});

export const addToShowroomSchema = (info: any): any => {
  const githubLink = getLinkByType(info.projectLinks, GIT_GITHUB)?.link;

  return {
    props: {
      GIT_REPO: {
        isRequired: true,
        isDisabled: true,
        validators: [
          () => {
            if (!info.userHasActiveRepository && !githubLink) {
              return [SHOWROOM_VALIDATION_ERRORS.NO_ACTIVITY_IN_THE_REPOSITORY];
            }
            return [false];
          },
        ],
      },
      projectLinks: {
        all: {
          props: {
            link: {
              validators: [
                (value: string, parentValues: IProjectLink) => {
                  /* if (
                    !value &&
                    requiredLinksTypes.includes(parentValues.linkType!)
                  ) {
                    return [SHOWROOM_VALIDATION_ERRORS.FIELD_IS_REQUIRED];
                  } */
                  if (
                    value &&
                    parentValues.linkType !== EMAIL &&
                    parentValues.linkType !== SLIDE_DECK &&
                    parentValues.linkType !== CASE_STUDY &&
                    !validURLRegExp.test(value)
                  ) {
                    return [SHOWROOM_VALIDATION_ERRORS.LINK_FORMAT_INVALID];
                  }
                  if (
                    value &&
                    parentValues.linkType === EMAIL &&
                    !emailRegExp.test(value)
                  ) {
                    return [SHOWROOM_VALIDATION_ERRORS.WRONG_EMAIL_FORMAT];
                  }
                  /* if (
                    !parentValues.file &&
                    parentValues.linkType === SLIDE_DECK
                  ) {
                    return [SHOWROOM_VALIDATION_ERRORS.ATTACH_YOUR_FILE];
                  } */
                  if (parentValues?.linkType === GIT_OTHER) {
                    if (info.BE_ERROR_MESSAGE && !githubLink) {
                      return [
                        SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK,
                      ];
                    }
                    if (!info.userHasActiveRepository && !githubLink) {
                      return [
                        SHOWROOM_VALIDATION_ERRORS.NO_ACTIVITY_IN_THE_REPOSITORY,
                      ];
                    }
                  }
                  return [false];
                },
              ],
            },
          },
        },
      },
    },
  };
};
