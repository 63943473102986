import { USER_API } from 'constants/apiConstants';
import { formatStringWithParams } from 'utils/stringUtils';
import { BaseService } from '../baseService';
import { UserInfo } from './userInfo';

class UserService extends BaseService {
  private static instance: UserService;

  private userInfo: UserInfo | null = null;

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  public async getUserProfile() {
    const instance = UserService.instance;
    const result = await instance.get<UserInfo>(USER_API.Info);
    if (result) instance.userInfo = result;
    return result;
  }

  public getUserProfilePicture(userId?: string | number) {
    const instance = UserService.instance;
    userId ??= instance.userInfo?.id;

    return userId
      ? formatStringWithParams(USER_API.UserProfilePicture, userId)
      : '';
  }

  public async getImageDataWithAuth(imageUrl: string, getBlob: boolean) {
    const result = await UserService.instance.get<any>(imageUrl, {
      responseType: getBlob ? 'blob' : 'text',
    });
    return result;
  }
}

export default UserService.getInstance();
