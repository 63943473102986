import {
  ModalBlocker,
  ModalHeader,
  ModalWindow,
  ScrollBars,
  useForm,
  Text,
  SuccessNotification,
  Panel,
  FlexRow,
} from '@epam/promo';
import './styles.scss';
import {
  PROJECT_LINKS,
  joinUsersLinksWithMissingDefaultLinksFromTheForm,
} from '../add-to-showroom/helpers/utils';
import { INotification, useUuiContext } from '@epam/uui';
import { useEffect, useState } from 'react';
import { queryClient } from 'App';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { LinkDictionary } from '../add-to-showroom/models/interfaces';
import { EditShowroomProps } from '../add-to-showroom/models/interfaces';
import { STEPS_ADD_TO_SHOWROOM } from '../add-to-showroom/constants';
import { STEPS_PROJECT_FORM } from 'modules/project-management/update/constants';
import { LINK_TYPES } from '../project-links/constants';
import { MAX_PROJECT_LINKS } from 'constants/uiConstants';
import { editShowroomSchema } from './models/validationSchema';
import { prepareFormData } from 'modules/project-management/update/utils';
import { ProjectMeta } from 'modules/project-management/update/components/project-meta';
import { Cover } from 'modules/project-management/update/components/cover';
import { Keywords } from 'modules/project-management/update/components/keywords';
import { Technologies } from 'modules/project-management/update/components/technologies';
import { ProjectStatus } from 'models/enums';
import { BlockerModal } from 'component/BlockerModal';
import { createProjectDataObject } from 'modules/project-management/update/utils';
import { EndDate } from 'modules/project-management/update/components/end-date';
import { showErrorMessages } from 'component/errorMessagesRenderer/errorMessagesRenderer';
import { validationErrors } from 'models/errors';
import dayjs from 'dayjs';
import Footer from './Footer';
import projectManagementService from 'services/api/project-management/projectManagementService';
import projectListService from 'services/api/project-list/projectListService';
import StepperController from 'component/Stepper';
import EventsSelector from 'modules/project-management/update/components/event/EventsSelector';
import WarningMessage from '../../../../../component/Artifacts/WarningMessage';
import OptionalArtifactsForm from '../add-to-showroom/tabs/OptionalArtifactsForm';
import RequiredArtifactsForm from '../add-to-showroom/tabs/RequiredArtifactsForm';
import ProjectManagementService from 'services/api/project-management/projectManagementService';
import { ProjectLinkType } from 'modules/project-management/models/interfaces';

function EditShowroom({
  modalProps,
  project,
  info,
  links,
  isProjectInShowroom,
  editorSwitch,
}: EditShowroomProps) {
  const [currentLinkCount, setCurrentLinkCount] = useState(0);
  const [dictionary, setDictionary] = useState<ProjectLinkType[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const svc = useUuiContext();
  const steps = [
    STEPS_PROJECT_FORM.ABOUT_PROJECT,
    STEPS_ADD_TO_SHOWROOM.REQUIRED_ARTIFACTS,
    STEPS_ADD_TO_SHOWROOM.OPTIONAL_ARTIFACTS,
  ];
  const isReachedLinkLimit = currentLinkCount === MAX_PROJECT_LINKS;
  const { DOCS_DECKS, SLIDE_DECK, CASE_STUDY } = LINK_TYPES;
  const gitlabUrl = info?.labUrl;

  const { lens, save, isChanged, validate } = useForm({
    validationOn: 'save',
    value: {
      id: project?.id ?? 0,
      status: project?.status ?? ProjectStatus.INITIAL,
      projectMetadata: {
        name: project?.name,
        description: project?.description,
      },
      cover: {
        imgUrl: project?.previewURL,
        uploadedImage: null,
      },
      projectTimestamps: {
        plannedStartDate: project?.plannedStartDate,
        plannedEndDate:
          (project?.plannedEndDate &&
            dayjs(project.plannedEndDate).format('YYYY-MM-DD')) ??
          dayjs().add(3, 'month').format('YYYY-MM-DD'),
      },
      keywords: project?.keywords,
      technologies: project?.technologies?.map(
        (tech: { skilloId: string; name: string }) => {
          return {
            skilloId: tech.skilloId,
            id: tech.skilloId,
            name: tech.name,
          };
        }
      ),
      event: project &&
        project.challenge && {
          id: project.challenge.challengeId,
          name: project.challenge.challengeName,
        },
      GIT_REPO: gitlabUrl,
      projectLinks: joinUsersLinksWithMissingDefaultLinksFromTheForm(links),
    },
    onSave: async (editShowroomFormData) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);

      const showroomData: any = await createProjectDataObject(
        editShowroomFormData
      );

      const preparedFormData = prepareFormData(showroomData);
      const fetchedData = await projectListService.getProjectById(project.id);
      /*
       * Project's dateLastUpdated field is not updated on the BE if
       * project's keywords, technologies or links have been changed.
       * Therefore, comparing two JSONs to check if the project has been changed.
       * */
      if (
        JSON.stringify(project.dateLastUpdated) !==
        JSON.stringify(fetchedData.dateLastUpdated)
      ) {
        return Promise.reject('The project has changed');
      }
      if (
        fetchedData.pictureURL &&
        !lens.prop('cover').toProps().value.uploadedImage
      ) {
        await projectListService.deleteProjectCoverImg(project.id);
      }
      await projectManagementService.updateProject(
        lens.prop('id').toProps().value,
        preparedFormData
      );
      await Promise.all([
        queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
        queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
        queryClient.refetchQueries([QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST]),
        queryClient.refetchQueries([
          QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
          lens.prop('id').toProps().value,
        ]),
      ]);
      return Promise.resolve({ form: editShowroomFormData });
    },
    onSuccess: (project) => {
      useCloseBlocker(svc);
      modalProps.success(true);
      svc.uuiNotifications
        .show(
          (props: INotification) => (
            <SuccessNotification {...props}>
              <Text>
                {`You have successfully updated the project "${project.projectMetadata.name}".`}
              </Text>
            </SuccessNotification>
          ),
          { position: 'bot-left', duration: 5 }
        )
        .catch(() => null);
    },
    onError: (errors: validationErrors) => {
      useCloseBlocker(svc);
      showErrorMessages(svc, errors);
    },
    getMetadata: editShowroomSchema,
  });

  const projectLinks = lens.prop(PROJECT_LINKS).get();
  const isWithAtLeastOneRequiredFieldInvalid =
    lens.prop(DOCS_DECKS).toProps().isInvalid ||
    lens.prop(SLIDE_DECK).toProps().isInvalid ||
    lens.prop(CASE_STUDY).toProps().isInvalid;

  const showLeaveConfirm = useLeaveConfirm(modalProps, isChanged);
  const totalSteps = () => steps.length;

  const isLastStep = (n = 1) => activeStep === totalSteps() - n;

  const handleNext = () => {
    completeStep();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((currentAciveStep) => currentAciveStep - 1);
  };

  const completeStep = () => {
    const newCompleted = completed;
    newCompleted[activeStep + 1] = true;
    setCompleted(newCompleted);
  };

  const stepContent: { [key: number]: ReactJSXElement } = {
    1: (
      <Panel margin={'24'}>
        <FlexRow spacing={'18'} alignItems={'top'}>
          <ProjectMeta lens={lens.prop('projectMetadata')} />
          <Cover lens={lens.prop('cover')} />
        </FlexRow>
        <Keywords lens={lens.prop('keywords').default([])} />
        <Technologies lens={lens.prop('technologies').default([])} />
        <EventsSelector
          lens={lens.prop('event')}
          editorSwitch={editorSwitch}
          projectMembers={project.members}
          isNewProject={false}
        />
        <EndDate lens={lens.prop('projectTimestamps')} isNewProject={false} />
      </Panel>
    ),
    2: (
      <RequiredArtifactsForm
        lens={lens}
        dictionary={dictionary}
        projectLinks={projectLinks}
        isReachedLinkLimit={isReachedLinkLimit}
        currentLinkCount={currentLinkCount}
        className="edit-showroom-modal"
        gitlabUrl={gitlabUrl}
      />
    ),
    3: (
      <OptionalArtifactsForm
        lens={lens}
        dictionary={dictionary}
        projectLinks={projectLinks}
        isReachedLinkLimit={isReachedLinkLimit}
        isProjectInShowroom={isProjectInShowroom}
        currentLinkCount={currentLinkCount}
        className="edit-showroom-modal"
      />
    ),
  };

  useEffect(() => {
    (async () => {
      const linksDictionary: LinkDictionary =
        await ProjectManagementService.getProjectLinks();
      setDictionary(linksDictionary.items);
    })();
  }, []);

  useEffect(() => {
    setCurrentLinkCount(projectLinks.length + 1); // add + 1 because 'Code in Gitlab' link is not part of the user's projectLinks but is the part of users info
  }, [projectLinks]);

  return (
    <ModalBlocker blockerShadow="dark" {...modalProps} abort={showLeaveConfirm}>
      <ModalWindow cx="edit-showroom-modal">
        <ModalHeader
          borderBottom
          title="Edit Showroom"
          onClose={showLeaveConfirm}
        />
        <StepperController
          activeStep={activeStep}
          steps={steps}
          stepClick={setActiveStep}
          stepsCompleted={completed}
          enableAllSteps
        />
        {activeStep === 1 && isWithAtLeastOneRequiredFieldInvalid && (
          <WarningMessage
            message="Before updating Showroom project, the below requirements have to be satisfied:"
            className={'edit-showroom-modal__warning-alert'}
          />
        )}
        <ScrollBars hasTopShadow hasBottomShadow cx="edit-showroom-modal__main">
          {stepContent[activeStep + 1]}
        </ScrollBars>
        <Footer
          lens={lens}
          saveForm={save}
          validate={validate}
          isNotLastStep={!isLastStep(1)}
          isFirstStep={activeStep === 0}
          setNextStep={handleNext}
          setActiveStep={setActiveStep}
          setPreviousStep={handlePrevious}
          projectLinks={projectLinks}
          projectId={parseInt(info.id)}
        />
      </ModalWindow>
    </ModalBlocker>
  );
}
export default EditShowroom;
