import { DatePicker } from '@epam/promo';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

export type Props = {
  projectEndDate: (data: string) => void;
};
const ProjectEndDate: React.FC<Props> = ({ projectEndDate }) => {
  const [endDate, setEndDate] = useState('');
  const minSelectableDate = dayjs(dayjs()).subtract(1, 'day').valueOf();

  return (
    <DatePicker
      filter={(day: Dayjs) => day.valueOf() >= minSelectableDate}
      isRequired={true}
      placeholder="DD/MM/YYYY"
      format="DD/MM/YYYY"
      value={endDate}
      onValueChange={(newDate: string) => {
        setEndDate(newDate);
        projectEndDate(newDate);
      }}
    />
  );
};
export default ProjectEndDate;
