import {
  Checkbox,
  FlexRow,
  LabeledInput,
  Panel,
  TextArea,
  TextInput,
  Text,
  PickerInput,
  DataPickerRow,
  PickerItem,
} from '@epam/promo';
import {
  DataRowProps,
  ILens,
  LazyDataSourceApiRequest,
  LazyDataSourceApiResponse,
  useLazyDataSource,
} from '@epam/uui';
import { EventIF } from 'modules/events/models/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { VALIDATION_ERRORS } from '../../event-schema';
import { useLensProps } from 'hooks/useLens';
import '../../../manage/events-field.scss';
import { ITelescopeMember } from 'modules/project-list/components/project-card/modal';
import { normalizeUsersResponse } from 'modules/project-management/details/components/add-team-member/utils';

interface EventDetailsProps {
  lens: ILens<EventIF>;
  isNewEvent: boolean;
  duplicateEventName: boolean;
  isUrlInvalid: boolean;
  organizersExternalId: ITelescopeMember[];
}

const EventDetails = (props: EventDetailsProps) => {
  const { getFullPropObject, setProp, getPropValue } = useLensProps(props.lens);
  const { organizersExternalId } = props;
  const isGlobal = getPropValue('global');
  const location = getPropValue('location');

  const loadTelescopeUsers = useCallback(
    async (
      request: LazyDataSourceApiRequest<ITelescopeMember, string, unknown>
    ): Promise<LazyDataSourceApiResponse<ITelescopeMember>> => {
      return normalizeUsersResponse(request.search || '', []);
    },
    []
  );

  const membersDataSource = useLazyDataSource<
    ITelescopeMember,
    string,
    unknown
  >(
    {
      api: loadTelescopeUsers,
      getId: (member) => member.externalId,
    },
    []
  );

  const [organizerEmails, setOrganizerEmails] = useState<ITelescopeMember[]>(
    []
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (membersDataSource && organizersExternalId.length > 0 && !isDataLoaded) {
      setOrganizerEmails(organizersExternalId);
      setIsDataLoaded(true);
    }
  }, [membersDataSource, organizersExternalId, isDataLoaded]);

  const handleOrganizerEmailChange = (
    newValues: (ITelescopeMember | null)[] | undefined
  ) => {
    const filteredValues = (newValues || []).filter(
      (value) => value !== null
    ) as ITelescopeMember[];

    const limitedValues = filteredValues.slice(0, 5);

    if (limitedValues) {
      setOrganizerEmails(limitedValues);
      setProp(
        'organizersExternalId',
        limitedValues.map((value) => value.externalId)
      );
    } else {
      console.error(
        'Unexpected value received in handleOrganizerEmailChange:',
        newValues
      );
    }
  };
  const renderUserRow = (props: DataRowProps<ITelescopeMember, string>) => (
    <DataPickerRow
      {...props}
      key={props.rowKey}
      alignActions="center"
      padding="12"
      checkbox={{ isVisible: false }}
      cx={props.isSelected ? 'selected-item' : ''}
      renderItem={(item) => (
        <PickerItem
          {...props}
          title={item?.displayName || 'Unnamed Member'}
          subtitle={item?.email || 'No Email Available'}
          checkbox={{ isVisible: false }}
        />
      )}
    />
  );

  return (
    <>
      <Panel>
        <FlexRow vPadding="24">
          <LabeledInput
            label="Event Name"
            {...getFullPropObject('name')}
            isRequired
          >
            <TextInput
              placeholder="Enter event name"
              maxLength={60}
              {...getFullPropObject('name')}
              {...props.lens.prop('name').toProps()}
            />
          </LabeledInput>
        </FlexRow>
        {props.duplicateEventName && (
          <FlexRow cx="footnotes" alignItems="top">
            <Text fontSize="12" lineHeight="18" size="24" cx="invalid">
              {VALIDATION_ERRORS.EVENT_NAME_EXISTS}
            </Text>
          </FlexRow>
        )}
      </Panel>
      <Panel>
        <FlexRow vPadding="24">
          <LabeledInput
            label="Description"
            {...getFullPropObject('description')}
            isRequired
          >
            <TextArea
              rows={6}
              placeholder="Describe the objective of the event and how you think it can bring value"
              {...getFullPropObject('description')}
            />
          </LabeledInput>
        </FlexRow>
      </Panel>
      <Panel cx="has-margin-bottom">
        <FlexRow vPadding="24">
          <LabeledInput
            label="Home Site URL"
            {...getFullPropObject('homeSiteUrl')}
            isRequired
          >
            <TextInput
              placeholder="https://..."
              {...getFullPropObject('homeSiteUrl')}
            />
          </LabeledInput>
        </FlexRow>
        {props.isUrlInvalid && (
          <FlexRow cx="footnotes" alignItems="top">
            <Text fontSize="12" lineHeight="18" size="24" cx="invalid">
              {VALIDATION_ERRORS.DUPLICATE_HOME_SITE_LINK}
            </Text>
          </FlexRow>
        )}
      </Panel>
      <Panel cx="has-margin-bottom has-margin-top">
        <FlexRow>
          <LabeledInput
            label="Event Organizers"
            {...getFullPropObject('organizersExternalId')}
            isRequired
          >
            <PickerInput
              dataSource={membersDataSource}
              value={organizerEmails}
              onValueChange={handleOrganizerEmailChange}
              renderRow={renderUserRow}
              entityName="organizer"
              selectionMode="multi"
              valueType="entity"
              maxItems={5}
              minCharsToSearch={2}
              placeholder={
                organizerEmails.length > 0 ? '' : 'Enter event organizer email'
              }
              disableClear={false}
              getName={(item) => item.displayName || item.email || 'Unknown'}
              renderFooter={() => null}
              searchPosition="input"
            />
          </LabeledInput>
        </FlexRow>
      </Panel>
      <Panel cx="has-margin-bottom has-margin-top">
        <FlexRow vPadding="24">
          <LabeledInput label="Location" {...getFullPropObject('location')}>
            <TextInput
              cx={isGlobal ? 'uui-pale' : ''}
              placeholder="Enter a capitalized country name (e.g. Poland)"
              maxLength={500}
              {...getFullPropObject('location')}
              isDisabled={isGlobal}
            />
          </LabeledInput>
        </FlexRow>
        <FlexRow>
          <Checkbox label="Global" {...getFullPropObject('global')} />
        </FlexRow>
      </Panel>
    </>
  );
};

export default EventDetails;
