import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorHandler, skinContext as promoSkinContext } from '@epam/promo';
import { Modals, Snackbar } from '@epam/uui-components';
import { ContextProvider, UuiContexts } from '@epam/uui';
import './app.scss';
import Header from './modules/main/header';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserInfoProvider } from './services/auth-client/user-info-provider';
import { createBrowserHistory } from 'history';
import { DictionaryProvider } from 'services/dictionary/dictionary-provider';
import { ScreenSizeProvider } from './services/screen';
import { TimezonesProvider } from './services/timezone';
import { Content } from 'modules/main/content/content';

const getEditorSwitchFromLocalStorage = () => {
  const editorSwitch = localStorage.getItem('editorSwitch');
  return editorSwitch === 'true';
};

const getEventOrganizerSwitchFromLocalStorage = () => {
  const eventOrganizerSwitch = localStorage.getItem('eventOrganizerSwitch');
  return eventOrganizerSwitch === 'true';
};

const history = createBrowserHistory();
export const queryClient = new QueryClient();

export const EditorSwitchContext = React.createContext<boolean>(false);
export const EventOrganizerSwitchContext = React.createContext<boolean>(false);

function App() {
  const [editorSwitch, setEditorSwitch] = useState(
    getEditorSwitchFromLocalStorage()
  );
  const [eventOrganizerSwitch, setEventOrganizerSwitch] = useState(
    getEventOrganizerSwitchFromLocalStorage()
  );

  return (
    <ContextProvider<any, UuiContexts>
      onInitCompleted={(context) => context}
      skinContext={promoSkinContext}
      history={history as any}
    >
      <QueryClientProvider client={queryClient}>
        <ScreenSizeProvider>
          <UserInfoProvider>
            <TimezonesProvider>
              <DictionaryProvider>
                <ErrorHandler>
                  <EditorSwitchContext.Provider value={editorSwitch}>
                    <EventOrganizerSwitchContext.Provider
                      value={eventOrganizerSwitch}
                    >
                      <Router>
                        <Header
                          editorSwitch={editorSwitch}
                          setEditorSwitch={setEditorSwitch}
                        />
                        <Content editorSwitch={editorSwitch} />
                        <Modals />
                        <Snackbar />
                      </Router>
                    </EventOrganizerSwitchContext.Provider>
                  </EditorSwitchContext.Provider>
                </ErrorHandler>
              </DictionaryProvider>
            </TimezonesProvider>
          </UserInfoProvider>
        </ScreenSizeProvider>
      </QueryClientProvider>
    </ContextProvider>
  );
}

export default App;
