import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Timezone } from 'services/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateFormatter = (
  date: Dayjs | string,
  format = 'DD MMM, YYYY'
): string => {
  if (typeof date === 'string') {
    date = dayjs.utc(date);
  }
  return date && date.isValid() ? date.format(format) : '';
};

export const getTimeZone = (timezones: Timezone[], val: string | number) =>
  timezones.find((x: any) => x.id === val || x.name === val);

export const getTimezoneBasedDateTime = (
  timezone: string,
  dateTime: string,
  dateFormat = 'YYYY-MM-DD'
) => {
  const convertedDate = toTimeZoneBasedDate(dateTime, timezone);
  return {
    date: convertedDate.format(dateFormat),
    time: {
      hours: convertedDate.hour(),
      minutes: convertedDate.minute(),
    },
  };
};

export const toTimeZoneBasedDate = (
  date: string | Date,
  timeZone: string
): Dayjs => {
  return dayjs.tz(date, timeZone);
};

export const getCurrentDate = () => dayjs();

export const getLocalTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDateForBE = (date: Dayjs): string => {
  return date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

export const convertTimeZone = (
  dateStr: string | Date,
  originalTimeZone: string,
  targetTimeZone: string
): Dayjs => {
  // Convert Date object to string if needed
  if (dateStr instanceof Date) {
    dateStr = dateStr.toISOString();
  }
  const date = dayjs.tz(dateStr, originalTimeZone);
  return date.tz(targetTimeZone);
};
