import userService from 'services/api/user/userService';

const createCanvas = (size: number) => {
  const ratio = window.devicePixelRatio;
  const canvas = document.createElement('canvas');
  canvas.width = size * ratio;
  canvas.height = size * ratio;
  canvas.style.width = size + 'px';
  canvas.style.height = size + 'px';
  canvas.getContext('2d')?.scale(ratio, ratio);
  return canvas;
};

export const drawInitials = (
  initials = 'GA',
  bgFill: 'grey' | 'green' = 'grey'
): string => {
  const fillColor = bgFill === 'grey' ? '#CED0DB' : '#88CC00';
  const textRatio = 2.5;
  const canvasSize = 250;
  const textSize = canvasSize / textRatio;
  const textPosX = canvasSize / 2;
  const textPosY = canvasSize / 1.9;
  const string = `${textSize}px sans-serif`;
  const image = createCanvas(canvasSize);
  const ctx = image.getContext('2d');
  if (ctx) {
    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, canvasSize, canvasSize);
    ctx.fillStyle = '#FFF';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = string;
    ctx.fillText(initials.slice(0, 2).toUpperCase(), textPosX, textPosY);
  }
  return image.toDataURL();
};

export const fetchImage = async (
  imgURL: string,
  initials: string,
  extraAttempts = 1
): Promise<string> => {
  try {
    const getBlob = imgURL.endsWith('preview');
    const img = await userService.getImageDataWithAuth(imgURL, getBlob);
    if (img.size !== 0 && getBlob) {
      return URL.createObjectURL(img);
    } else if (img.length !== 0 && !getBlob) {
      return img;
    } else {
      return drawInitials(initials, 'green');
    }
  } catch (err) {
    console.error('Image fetch failed: ' + err);

    return extraAttempts > 0
      ? fetchImage(imgURL, initials, extraAttempts - 1)
      : '';
  }
};
