import { EVENTS_API } from 'constants/apiConstants';
import { BaseService } from '../baseService';
import { EventList, EventResponse } from 'modules/events/models/interfaces';

class EventManagementServices extends BaseService {
  private static instance: EventManagementServices;

  public static getInstance(): EventManagementServices {
    if (!EventManagementServices.instance) {
      EventManagementServices.instance = new EventManagementServices();
    }
    return EventManagementServices.instance;
  }

  public getEvents(
    amount: number,
    startPoint: number,
    searchPhrase: string,
    isValidated = false
  ) {
    return EventManagementServices.instance.get<EventList>(
      EVENTS_API.GetEvents(amount, startPoint, searchPhrase, isValidated)
    );
  }

  public deleteEvent(eventId: number) {
    return EventManagementServices.instance.delete(
      EVENTS_API.DeleteEventById(eventId)
    );
  }

  public editEvent(eventId: number | string, eventData: any) {
    return EventManagementServices.instance.put(
      EVENTS_API.EditEvent(eventId),
      eventData
    );
  }
  public getEvent(eventId: number | string) {
    return EventManagementServices.instance.get<EventResponse>(
      EVENTS_API.GetEventById(eventId)
    );
  }
  public createEvent(eventData: any) {
    return EventManagementServices.instance.post(
      EVENTS_API.CreateEvent(),
      eventData
    );
  }

  public getEventNames() {
    return EventManagementServices.instance.get(EVENTS_API.GetEventNames());
  }
}

export default EventManagementServices.getInstance();
